.tarotSection {
    width:              100%;
    min-height:         100vh;
    overflow:           hidden;
    display:            flex;
    flex-direction:     column;
    align-items:        center;
    justify-content:    center;
    padding:            3em 1em;
    background-color:   var(--off-white-color);
    text-align:         center;
}

.tarotSection h2 {
    width:              100%;
    font-size:          2.5em;
    margin-bottom:      1em;
    text-align:         center;
}

.content {
    display:            flex;
    flex-direction:     column;
    align-items:        center;
    text-align:         center;
    width:              90%;
    max-width:          1100px;
    gap:                1.5em;
}

.textContainer {
    order:              1;
    flex:               1;
    text-align:         center;
    max-width:          45em;
    font-size:          1.3em;
    line-height:        1.6;
}

.textContainer p {
    margin-bottom:      1.5em;
    color:              #444;
}

.imageContainer {
    order:              2;
    flex:               1;
    display:            flex;
    justify-content:    center;
    align-items:        center;
    max-width:          45em;
    margin:             0em 2em;
}

.image {
    width:              100%;
    height:             auto;
    border-radius:      1.5em;
}


@media (max-width: 1024px) {
    .content {
        flex-direction:   column;
        align-items:      center;
        text-align:       center;
        gap:              1.5em;
    }

    .textContainer {
        order:            1;
        max-width:       80%;
        text-align:      center;
        font-size:      1.2em;
        line-height:    1.4;
    }

    .imageContainer {
        order:            2;
        max-width:       80%;
    }

    .callToAction {
        order:            3;
    }
}

@media (max-width: 767px) {
    .tarotSection {
        padding:          2em 1em;
    }

    .tarotSection h2 {
        font-size:        2em;
    }

    .content {
        flex-direction:   column;
        width:            100%;
    }

    .textContainer {
        order:            1;
        width:            90%;
        padding:          1em;
        font-size:        1em;
    }

    .imageContainer {
        order:            2;
        width:            90%;
    }

    .callToAction {
        order:            3;
    }
}
