.commonQuestions {
    position:           relative;
    width:              100%;
    min-height:         90vh;

    display:            flex;
    justify-content:    center;
    align-items:        center;

    box-sizing:         border-box;
    background-color:   var(--light-gray-color);
    color:              var(--black-color);
}

.row {
    display:            flex;
    flex-wrap:          nowrap;
    justify-content:    space-between;
    align-items:        flex-start;
    gap:                2em;
    max-width:          80em;
    margin:             0 auto;
}

.column {
    flex:               1;
    min-width:          18em;
    padding:            1.5em;
}

.column h3 {
    margin-bottom:      0.5em;
    font-size:          1.3em;
    font-family:        var(--primary-font);
    font-weight:        normal;
    font-style: italic;
}

.column p {
    font-size:          1.2em;
    line-height:        1.6;
}

/* Responsive behavior for mobile devices */
@media (max-width: 767px) {

    .row {
        flex-direction:     column;
        padding:            0 1em;  /* Adjust padding on smaller screens */
    }

    .column {
        width:              100%;
        margin-bottom:      1.5em;  /* Adds space between stacked columns */
        padding:            0 0.5em;
    }
}
