.SocialMediaLinks {
    width:              100%;
}

.SocialMediaLinks i {
    font-size:          2em;
}

.title h2 {
    text-align:         center;
}

.title p {
    margin-top:         -2em;
    text-align:         center;
}

.social-svg,
.socialIcon {
    transition:         transform 2.6s ease-in-out;
}

.social-svg,
.socialIcon:hover {
    transform:          scale(1.2);
}

.dFlex {
    display:            flex;
    justify-content:    space-between;
    align-items:        center;
}

.social-svg-icon .social-svg-icon {
    fill:               green;
}

.social-svg-icon:hover .social-svg-icon {
    fill:               red;
}
