.container {
    display:            flex;
    align-items:        center;
    justify-content:    center;
    min-height:         100vh;
    padding:            20px;
    box-sizing:         border-box;
    background-color:   #f9f9f9;
}

.column {
    max-width:          600px;
    width:              100%;
    text-align:         center;
    padding:            0 15px;
}

.title {
    font-size:          2.5rem;
    margin-bottom:      1rem;
    color:              #333;
}

.subtitle {
    font-size:          1.5rem;
    margin-bottom:      1rem;
    color:              #555;
}

.paragraph {
    font-size:          1rem;
    color:              #666;
}

.orderDetails {
    text-align: left; /* Align order details text to the left for better readability */
    margin-bottom: 1.5em; /* Add some space below the order details */
}

.orderDetails h3 {
    font-size: 1.2rem;
    margin-bottom: 0.5em;
    color: #444;
}

.orderDetails p {
    font-size: 1rem;
    color: #555;
    margin-bottom: 0.5em;
}

/* Media query for screens up to 767px */
@media (max-width: 767px) {
    .title {
        font-size: 2rem; /* Smaller title font size */
    }

    .subtitle {
        font-size: 1.2rem; /* Smaller subtitle font size */
    }

    .paragraph {
        font-size: 0.9rem; /* Smaller paragraph font size */
    }

    .orderDetails h3 {
        font-size: 1rem; /* Smaller order details heading font size */
    }

    .orderDetails p {
        font-size: 0.9rem; /* Smaller order details paragraph font size */
    }
}
