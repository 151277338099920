.buyNowContainer {
    display:            flex;
    flex-direction:     column;
    align-items:        center;
    justify-content:    flex-start; /* minimize unnecessary vertical gaps */

    background:         var(--sand-color);

    padding:            1em;
    border:             2px solid #f5a623;
    border-radius:      10px;
    box-shadow:         0 4px 8px rgba(0, 0, 0, 0.1);
    margin:             0 1em 1em 0;

    margin:             0.5em auto;
    width:              100%;
    max-width:          18em;
}

.priceContainer {
    text-align:         center;
    margin:             0;
    padding:            0;
}

.priceTag {
    font-family:        var(--primary-font);
    color:              #f5a623;

    margin:             0;
    font-size:          1.2em;
}

.priceDescription {
    *margin:             0;
    font-size:          0.9em;
    color:              #555;
}


.amount {
    font-size:          2.5em;
    margin:             0 5px;
    color:              #2e2e2e;
}


.submitButton {

    display:            flex;
    flex-direction:     row;

    margin:             1em auto;
    padding:            10px 20px;

    font-size:          1.2em;
    font-weight:        600;
    color:              #fff;
    background-color:   #e91e63;
    transition:         background-color 0.5s ease-in-out;

    border:             none;
    border-radius:      4px;

    cursor:             pointer;
    position:           relative;

    overflow:           hidden;

    text-shadow:        2px 2px 4px rgba(0, 0, 0, 0.8);
}

.submitButton:hover {
    background-color:       #b60940;
}

.submitButton:disabled {
    background-color:       #ccc;
    cursor:                 not-allowed;
}

.buttonContainer {
    display:                flex;
    align-items:            center;
    justify-content:        center;
    margin-top:             1em;
}

.agreementContainer {
    display:                flex;
    flex-direction:         row;
    align-items:            center;
    justify-content:        center;
    gap:                    0.5em; /* Space between checkbox, label, and button */
    padding:                0;
    margin:                 0;
}

.checkbox {
    margin:                 10px 10px 10px 0;
    cursor:                 pointer;
}

.checkboxLabel {

    margin:                 1em auto;

    cursor:                 pointer;
    font-family:            var(--tiny-font);
    font-size:              1em;
}

.smallPrint {
    font-size:              smaller;
    font-weight:            300;
    text-align:             right;
    width:                  100%;
}

.smallPrint a {
    text-decoration:        none;
    color:                  inherit;
}
