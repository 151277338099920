.cardSpread {
  position:         relative;
  width:            100vw;
  height:           100vh;
  overflow:         hidden;
  cursor:           grab;
}

.cardSpread:active {
  cursor:           grabbing;
}

.modalFadeIn {
  animation:        fadeIn 0.3s ease-in-out;
}

@keyframes fadeIn {
  from {
    opacity:        0;
    transform:      scale(0.9);
  }
  to {
    opacity:        1;
    transform:      scale(1);
  }
}

.modal {
  position:         absolute;
  top:              0;
  left:             0;
  width:            100%;
  height:           100%;
  background-color: rgba(0, 0, 0, 0.5);
  display:          flex;
  align-items:      center;
  justify-content:  center;
  z-index:          10;
}

.modalContent {
  background:       white;
  padding:          20px 40px;
  border-radius:    10px;
  text-align:       center;
  box-shadow:       0 4px 10px rgba(0, 0, 0, 0.25);
}

.modalContent h2 {
  margin-bottom:    20px;
  font-size:        1.8rem;
}

.modalContent button {
  margin:           10px;
  padding:          10px 20px;
  font-size:        1rem;
  cursor:           pointer;
  border:           none;
  border-radius:    5px;
  background-color: #007bff;
  color:            white;
  transition:       background-color 0.3s ease;
}

.modalContent button:hover {
  background-color: #0056b3;
}

.cardCounter {
  position:         absolute;
  top:              10px;
  width:            100%;
  text-align:       center;
  font-size:        1.5rem;
  font-weight:      bold;
  color:            #333;
  z-index:          10;
}
