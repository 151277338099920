.page {
    display:            flex;
    flex-direction:     column;
    align-items:        center;
    justify-content:    center;
    min-height:         100vh;
    padding:            2em;
    box-sizing:         border-box;
    overflow:           hidden;
}

.page h2 {
    margin:             1.2em 0 0 0;
}

.error {
    color:              #721c24;
    background-color:   #f8d7da;
    padding:            10px;
    border-radius:      5px;
    margin:             0.5em 0 1em 0;
}

.errorField {
    border:             2px solid #dc3545;
}

.currentStep {
    display:            flex;
    flex-direction:     column;
    justify-content:    space-between;
    min-height:         20em;
}

.container {
    display:            flex;
    flex-direction:     column;
    align-items:        flex-start;
    justify-content:    center;
    gap:                2em;
    width:              100%;
    max-width:          800px;
    margin:             auto;
}

.paragraphContainer {
    width:              100%;
    margin:             0 auto;
    padding:            2em;
}

.paragraphContainer {
    margin-top:         -1.5em;
    margin-bottom:      2em;
}

.paragraphContainer h3 {
    margin:             0 0 0 0;
}

.formContainer {
    width:              100%;
    max-width:          20em;

    margin:             0 auto;
    padding:            2em;

    display:            flex;
    flex-direction:     column;
    align-items:        center;

    border-radius:      0.5em;

    box-shadow:         0 2px 6px rgba(0, 0, 0, 0.6);
    background-color:   var(--light-salmon-color);
}

.formGroup {
    width:              100%;
    margin-bottom:      20px;
}

.horizontal {
    display:            flex;
    justify-content:    space-between;
    gap:                2em;
}

.formGroup input, .formGroup select {
    width:              100%;
    padding:            8px;
    box-sizing:         border-box;
}

.suggestions {
    list-style-type:    none;
    padding:            0;
    margin:             0;
    border:             1px solid #ccc;
    max-height:         150px;
    overflow-y:         auto;
    position:           absolute;
    background:         white;
    max-width:          20em;
    z-index:            100;
    /*width: calc(100% - 20px);*/
}

.suggestions li {
    padding: 8px;
    cursor: pointer;
}

.suggestions li:hover {
    background-color: #f0f0f0;
}

.customButton {
    padding: 10px 20px;
    margin: 10px;
    background-color: #007BFF;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    display: block;
    width: auto;
}

.customButton:hover {
    background-color: #0056b3;
}

.disabledButton {

    adding: 10px 20px;
    margin: 10px;
    background-color:   var(--disabled-btn-color);
    color:              var(--disabled-btn-txt-color);
    border:             none;
    border-radius:      5px;
    cursor:             not-allowed;
    display:            block;
    width:              auto;
}

@media (min-width: 768px) {
    .container {
        flex-direction: row;
    }
    .paragraphContainer, .formContainer {
        width: 50%;
    }
}
