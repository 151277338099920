.clockContainer {
    width:              auto;
    height:             80px;
    background-color:   var(--light-background-color);
    color:              var(--dark-text-color);
    border-radius:      10px;
    box-shadow:         0px 4px 8px rgba(0, 0, 0, 0.5);
    padding:            20px;
    display:            flex;
    flex-direction:     column;
    justify-content:    space-between;
    align-items:        center;
}

.timeDisplay {
    font-size:          2em;
    font-weight:        bold;
}

.dateDisplay {
    font-size:          1.2em;
}

.clockContainer h2 {
    font-size:          1.4em;
}
