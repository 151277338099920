
.profileButtons {
    display:            flex;
    flex-direction:     row;
    margin:             1em 0 0 0 ;
}

.profileButton {
    width:              70px;
    height:             70px;
    border-radius:      50%;
    border:             3px solid #cca300;
    object-fit:         cover;
    margin:             0 1em;
}
