.container {
    display:            flex;
    align-items:        center;
    justify-content:    center;
    min-height:         100vh;
    background-color:   #f0f0f0;
}

.profileContainer {
    display:            flex;
    flex-direction:     column;
    align-items:        center;
    justify-content:    center;
    background-color:   #fff;
    border-radius:      15px;
    box-shadow:         0 4px 8px rgba(0, 0, 0, 0.1);
    padding:            0.5em;
}

.profilePicContainer {
    display:            flex;
    justify-content:    center;
    align-items:        center;
    margin-top:         1em;
}

.profilePic {
    width:              150px;
    height:             150px;
    border-radius:      50%;
    border:             3px solid #e91e63;
    object-fit:         cover;
}

.profileName {
    font-size:          1.5em;
    color:              #333;
    margin-bottom:      0;
    margin-top:         0.2em;
}

.profileDetails {
    list-style-type:    none;
    padding:            0;
    font-size:          1.2em;
    color:              #333;
    text-align:         center;
    margin-top:         -0.2em;
}

.profileDetails li {

    margin:             0;
}
