.starSignCard {
    overflow:           hidden;
    justify-content:    center;
    align-items:        center;
    text-align:         center;

    border:             1px solid #ccc;
    border-radius:      1em;
    padding:            1em;
    width:              130px;
}


.starSignItem {
    width:              150px;
    padding:            10px;
    font-size:          1.3em;
    border:             1px solid #ccc;
    border-radius:      8px;
}

.starSignLink {
    display:            block;
}

.starSignImage {
    width:              100%;
    height:             auto;
}

.starSignImage {
    transition:         transform 0.3s ease-in-out;
}

.starSignImage:hover {
    transform:          scale(1.1);
}

.centeredText {
    margin:             0;
    padding:            0;
    text-align:         center;
    font-size:          1.1em;
}

.cardTitle {
    font-size:          1.9em;
    text-align:         center;
    margin:             0;
    padding:            0;
}


/* Media query for screens up to 768px */
@media (max-width: 768px) {
    .starSignCard {
        width: 120px;
        padding: 0.5em;
    }

    .starSignImage {
        width: 80%;
    }

    .cardTitle {
        font-size: 1.5em;
    }

    .centeredText {
        font-size: 0.9em;
    }
}

/* Media query for screens up to 480px */
@media (max-width: 480px) {
    .starSignCard {
        width: 100px;
        padding: 0.3em;
    }

    .starSignImage {
        width: 70%;
    }

    .cardTitle {
        font-size: 1.2em;
    }

    .centeredText {
        font-size: 0.8em;
    }
}
