.pageContainer {
    display:            flex;
    justify-content:    center;
    align-items:        center;
    height:             100vh;
    background-color:   #f4f4f9;
    margin:             0;
    padding:            0;
}

.box {
    background-color:   #ffffff;
    padding:            2em;
    border-radius:      0.5em;
    box-shadow:         0 0.5em 1em rgba(0, 0, 0, 0.1);
    max-width:          400px;
    text-align:         center;
}

.spinnerContainer {
    display:            flex;
    flex-direction:     column;
    align-items:        center;
}

.spinnerMessage {
    margin-top:         1em;
    font-size:          1.2em;
    color:              #333;
}

.messageContainer {
    display:            flex;
    flex-direction:     column;
    align-items:        center;
}

.title {
    font-size:          1.5em;
    margin-bottom:      0.5em;
    color:              #333;
}

.message {
    font-size:          1.1em;
    color:              #666;
}

.errorMessage {
    font-size:          1.1em;
    color:              red;
    margin-bottom:      1em;
}

.homeButton {
    margin-top:         1.5em;
    padding:            0.75em 1.5em;
    font-size:          1em;
    color:              #fff;
    background-color:   #e91e63;
    border:             none;
    border-radius:      0.25em;
    cursor:             pointer;
    text-decoration:    none;
    transition:         background-color 0.5s ease-in-out;
}

.homeButton:hover {
    background-color:   #b60940;
}
