.textContainer {
    display:            flex;
    flex-direction:     column;
    align-items:        center;
    padding:            0 1em;
    margin:             0 1em;
    border-radius:      10px;
    background-color:   #fff;
}

.title {
    font-size:          1.3em;
    color:              #333;
    margin-bottom:      1em;
    text-align:         center;
}

.imageContainer {
    display:            flex;
    justify-content:    center;
    align-items:        center;
    margin-bottom:      1em;
}

.starSignImage {
    width:              100px;
    height:             100px;
    border-radius:      50%;
    object-fit:         cover;
    border:             3px solid #e91e63;
}

.forecastContent {
    font-size:          1.2em;
    color:              #666;
    text-align:         center;
}

.settingSection {
    width:              100%;
    padding:            20px;
    margin-bottom:      20px;
    border-radius:      8px;
    background-color:   var(--salmon-color);
    box-shadow:         0 2px 4px rgba(0,0,0,0.2);
    text-align:         left;
}

/* Responsive design for mobile */
@media (max-width: 767px) {

    .textContainer {
        padding: 0.5em;
        margin: 0.5em;
    }

    .textContainer h2 {
        font-size:      2.2em;
    }

    .textContainer h3 {
        font-size:      1.6em;
    }

    .textContainer p {
        font-size:      1.2em;
    }

    .starSignImage {
        width: 80px;
        height: 80px;
    }

    .forecastContent {
        font-size: 1em;
    }
}
