
#top {
  position:             absolute;
  left:                 0;
  top:                  0;
}

.page {
    width:              100%;
    min-height:         100vh;
    overflow:           auto;
    box-sizing:         border-box;

    display:            flex;
    justify-content:    center;
    align-items:        center;
    width:              100%;
    padding:            6em 2em 6em 2em;
    background-color:   white;

}

.imageContainer {
    flex:               1;
    display:            flex;
    padding:            4em 1em;
    margin:             0em 1em;

    justify-content:    center;
    align-items:        center;
}

.imageContainer img {
    max-width:          100%;
    height:             auto;
    border-radius:      2em;
    box-shadow:         4px 4px 8px rgba(0, 0, 0, 1.2), 6px 6px 20px rgba(0, 0, 0, 1);
}

.textContainer {
    flex:               1;
    display:            flex;
    flex-direction:     column;
    padding:            0em 1em;
    margin:             0em 1em;
}

.textContainer h2 {
    margin-bottom:      0em;
}

.textContainer h3 {
    margin-bottom:      0em;
}

.textContainer p {
    font-size:          1.3em;
    line-height:        1.6;
    margin-bottom:      1.5em;
}


.submitButton {

    display:            flex;
    flex-direction:     row;

    margin:             1em auto;
    padding:            10px 20px;

    font-size:          1.2em;
    font-weight:        600;
    color:              #fff;
    background-color:   #e91e63;
    transition:         background-color 0.5s ease-in-out;

    border:             none;
    border-radius:      4px;

    cursor:             pointer;
    position:           relative;

    overflow:           hidden;

    text-shadow:        2px 2px 4px rgba(0, 0, 0, 0.8);
}

.submitButton:hover {
    background-color:   #b60940;
}

.submitButton:disabled {
    background-color:   #ccc;
    cursor:             not-allowed;
}

.buttonContainer {
    display:          flex;
    align-items:      center;
    justify-content:  center;
    margin-top:       1em;
}



.buyNowContainer {
    display:            flex;
    flex-direction:     column;
    align-items:        center;
    justify-content:    flex-start; /* minimize unnecessary vertical gaps */

    background:         var(--sand-color);

    padding:            1em;
    border:             2px solid #f5a623;
    border-radius:      10px;
    box-shadow:         0 4px 8px rgba(0, 0, 0, 0.1);
    margin:             0 1em 1em 0;

    margin:             0.5em auto;
    width:              100%;
    max-width:          18em;
}

.priceContainer {
    text-align:         center;
    margin:             0;
    padding:            0;
}

.priceTag {
    /*display:            flex;
    align-items:        baseline;*/
    font-family:        var(--primary-font);
    color:              #f5a623;

    margin:             0;
    font-size:          1.2em;
}

.priceDescription {
    *margin:             0;
    font-size:          0.9em;
    color:              #555;
}


.amount {
    font-size:          2.5em;
    margin:             0 5px;
    color:              #2e2e2e;
}


.ctaButton {

    display:                flex;
    align-items:            center;
    text-align:             center;
    text-decoration:        none;

    padding:                0.8em 1.5em;
    margin:                 0 0 0 2em;
    font-size:              1.2em;
    color:                  #fff;
    background-color:       #e91e63;
    border:                 none;
    border-radius:          10px;
    cursor:                 pointer;
    transition:             background-color 0.5s ease-in-out;
}

.ctaButton:hover {
    background-color:       #b60940;
}

.agreementContainer {
    display:                flex;
    flex-direction:         row;
    align-items:            center;
    justify-content:        center;
    gap:                    0.5em; /* Space between checkbox, label, and button */
    padding:                0;
    margin:                 0;
}

.checkbox {
    margin:                 10px 10px 10px 0;
    cursor:                 pointer;
}

.checkboxLabel {

    margin:                 1em auto;

    cursor:                 pointer;
    font-family:            var(--tiny-font);
    font-size:              1em;
}

.smallPrint {
    font-size:  smaller;
    text-align: right;
    width:      100%;
}

.smallPrint a {
    text-decoration: none;
    color: inherit;
}


@media (max-width: 767px) {

    .page {
        flex-direction:         column;
        height:                 auto;
        padding:                2em 1em;
        overflow:               auto;
        box-sizing:             border-box;
    }

    .imageContainer {
        width:                  90%;

        padding:                2em 0em 4em 0em;
        margin:                 0;

        display:                flex;
        flex-direction:         column;
        justify-content:        center;
        align-items:            center;
    }

    .imageContainer img {
        max-width:              90%;
        height:                 auto;
        border-radius:          2em;
        box-shadow:             4px 4px 8px rgba(0, 0, 0, 1.2), 6px 6px 20px rgba(0, 0, 0, 1);
    }

    .textContainer {
        width:                  90%;
        margin:                 0;
        padding:                1em;
    }

    .textContainer {
        text-align:             center;
        padding:                1em;
    }

    .textContainer h2 {
        font-size:              2.2em;
    }

    .textContainer h3 {
        font-size:              1.6em;
    }

    .textContainer p {
        font-size:              1.2em;
        text-align:             justify;
    }

    .buttonContainer {
        flex-direction:         column;
    }

    .priceContainer {
        margin-right:           0;
        margin-bottom:          1em;
    }

    .ctaButton {
        font-size:              1.2em;
    }
}


@media (max-width: 767px) {
    .buyNowContainer {
        width: 90%;
        margin: 0.5em auto;
        padding: 0.5em;
        box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
    }

    .priceContainer {
        text-align: center;
        padding:    0;
        margin:     0;
    }

    .priceTag {
        font-size: 1.6em;
    }

    .priceDescription {
        font-size: 0.8em;
    }

    .submitButton {
        padding:    8px 16px;
        font-size:  1em;
    }

    .agreementContainer {
        flex-direction: row;
        align-items:    center;
        margin:         0;
    }

    .checkboxLabel {
        text-align: center;
        font-size: 0.9em;
    }
}
