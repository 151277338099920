.page {
    width:              100%;
    display:            flex;
    flex-direction:     column;
    align-items:        center;
    padding:            20px;
    box-sizing:         border-box;
    min-height:         100vh;
}

.dashboardContainer {
    display:            flex;
    flex-direction:     row;
    width:              100%;
    max-width:          1200px;
    margin-top:         6em;
    box-shadow:         0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius:      10px;
    overflow:           hidden;
}

.mainContent h1, .mainContent h2, .mainContent h3 {
    text-align:         left;
    font-family:        var(--primary-font);
    font-size:          1.3em;
    width:              100%;
    margin-bottom:      0;
    padding:            0;
}


.sidebar {
    flex:               1;
    display:            flex;
    flex-direction:     column;
    background-color:   #f9f9f9;
    padding:            20px;
    box-shadow:         4px 0 8px rgba(0, 0, 0, 0.1);
}

.menuOptions {
    margin:             1em 0;
    display:            flex;
    flex-direction:     column;
    align-items:        center;
}

.menuOptions button {
    background:         none;
    border:             none;
    padding:            10px;
    font-size:          1rem;
    text-align:         center;
    cursor:             pointer;
    transition:         background-color 0.3s ease;
    width:              100%;
}

.menuOptions button:hover {
    background-color:   #e0e0e0;
}

.mainContent {
    flex:               3;
    flex-wrap:          wrap;
    padding:            20px;
    background-color:   white;
    min-height:         80vh;
    display:            flex;
    flex-direction:     row;
    justify-content:    space-around;
    gap:                20px;
}
