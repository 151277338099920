.loveHoroscopeSection {
    width:              100%;
    min-height:         82vh;
    overflow:           hidden;
    display:            flex;
    justify-content:    center;
    align-items:        center;
    padding:            0em 2em 0em 2em;  /* Increased bottom padding for spacing */
    background-color:   var(--light-gray-color);
    margin-bottom:      4em;
}

.content {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
}

.imageContainer {
    flex: 1;
    text-align: center;
    max-width: 50%;
    padding: 2em;
}

.imageContainer h2,
.imageContainer h3 {
    text-align: center;
    margin-bottom: 1em;
    color: #333;
}

.loveImage {
    max-width: 100%;
    height: auto;
    border-radius: 2em;
    box-shadow: 4px 4px 8px rgba(0, 0, 0, 1.2), 6px 6px 20px rgba(0, 0, 0, 1);
}

.textContainer {
    flex: 1;
    max-width: 50%;
    padding: 2em;
}

.textContainer p {
    margin-bottom: 1.5em;
    font-size: 1.3em;
    line-height: 1.6;
    color: #555;
}

/* Media query for tablets (768px - 1024px) */
@media (max-width: 1024px) {
    .content {
        flex-direction: column;
    }

    .imageContainer, .textContainer {
        max-width: 80%;
        text-align: center;
    }

    .textContainer p {
        font-size: 1.2em;
    }
}

/* Media query for mobile screens (up to 767px) */
@media (max-width: 767px) {
    .content {
        flex-direction: column;
    }

    .imageContainer, .textContainer {
        max-width: 100%;
        text-align: center;
    }

    .textContainer p {
        font-size: 1.2em;
    }
}
