.page {
    display:            flex;
    align-items:        center;
    justify-content:    center;
    min-height:         100vh;
    padding:            20px;
    box-sizing:         border-box;
}

.container {
    display:            flex;
    flex-direction:     column;
    text-align:         center;
    align-items:        center;
    justify-content:    center;

    width:              100%;
    max-width:          360px;
}

.container h2 {
    margin-top: -5em;
}

.form {
    display:            flex;
    flex-direction:     column;
    width:              100%;

    background-color:   var(--light-salmon-color);
    padding:            2em;

    border-radius:      0.8em;
    box-shadow:         0 4px 8px rgba(0, 0, 0, 0.4);
}

.input,
.button,
.buttonDisabled {
    height:             2em;
    font-size:          1em;
    width:              300px;
    box-sizing:         border-box;
}

.input {
    margin: 1em 2em;
}

.button,
.buttonDisabled {
    border:             none;
    margin:             0.5em 1.4em;
    border-radius:      0.25em;
}

.button {
    color:              var(--white-color);
    background-color:   var(--blue-color);

    display:            flex;
    justify-content:    center;
    align-items:        center;

    cursor:             pointer;

    font-size:          1.2em;
    font-weight:        600;

    text-align:         center;
    text-shadow:        2px 2px 4px rgba(0, 0, 0, 0.5);

    cursor:             pointer;
    transition:         background-color 0.3s ease-in-out;
}

.button:hover:enabled {
    background-color:   var(--darkblue-color);
}

.buttonDisabled {
    background-color:   gray;
    color:              var(--white-color);

    font-size:          1.2em;
    font-weight:        600;

    text-align:         center;
    text-shadow:        2px 2px 4px rgba(0, 0, 0, 0.5);

    border:             none;
    cursor:             not-allowed;
}

.spinner {
    border:             4px solid rgba(255, 255, 255, 0.3);
    border-top:         4px solid white;
    border-radius:      50%;
    width:              24px;
    height:             24px;
    animation:          spin 1s linear infinite;
}

.error {
    color:              red;
    margin-bottom:      10px;
}

@keyframes spin {
    0%                  { transform:     rotate(0deg); }
    100%                { transform:     rotate(360deg); }
}

@media (max-width: 767px) {

    .page {
        margin: 0;
        padding: 2em 0 0 0;
    }

    .container {
        width: 100%;
        max-width: none;
        padding: 2em 1em;
    }

    .container h2 {
        padding: 2em 0 0 0;
    }

    .form {
        display:            flex;
        flex-direction:     column;
        align-items:        center;

        width:              21em;
        background-color:   var(--light-salmon-color);
        padding:            1em 1em;
        margin:             0 1em;
        border-radius:      0.5em;
        box-shadow:         0 2px 4px rgba(0, 0, 0, 0.3);
    }

    .input,
    .button,
    .buttonDisabled {
        height:             2em;
        font-size:          1em;
        max-width:          20em;
        margin:             0;
        box-sizing:         border-box;
    }

    .input {
        margin: 1em 0;
    }
}
