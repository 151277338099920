.starsignsSection {
    width:              100%;
    min-height:         100vh;
    overflow:           hidden;
    display:            flex;
    flex-direction:     column;
    align-items:        center;
    justify-content:    center;
    padding:            3em 1em;
    background-color:   var(--light-gray-color);
    text-align:         center;
}

.starsignsSection h2 {
    width:              100%;
    font-size:          2.5em;
    margin-bottom:      1em;
    text-align:         center;
}

.content {
    display:            flex;
    flex-direction:     column;
    align-items:        center;
    text-align:         center;
    width:              90%;
    max-width:          45em;
    gap:                1.5em;
    margin-bottom: 4em;
}

.imageContainer {
    order:              3;
    display:            flex;
    justify-content:    center;
    align-items:        center;
    max-width:          45em;
    margin:             0em 2em;
}

.mainImage {
    max-width:          100%;
    height:             auto;
    border-radius:      2em;
    box-shadow:         4px 4px 8px rgba(0, 0, 0, 1.2), 6px 6px 20px rgba(0, 0, 0, 1);
}

.textContainer {
    order:              1;
    text-align:         center;
    max-width:          45em;
    font-size:          1.3em;
    line-height:        1.6;
}

.textContainer p {
    margin-bottom:      1.5em;
    color:              #444;
}


@media (max-width: 1024px), (max-width: 767px) {
    .content {
        flex-direction:   column;
        align-items:      center;
        text-align:       center;
        width:            100%;
    }

    .textContainer {
        order:            1;
        width:            90%;
        padding:          1em;
        font-size:        1em;
    }

    .callToAction {
        order:            2;
    }

    .imageContainer {
        order:            3;
        width:            90%;
        max-width:       45em;
    }
}
