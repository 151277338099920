.liteYoutube {
  position:         relative;
  background-color: #000;
  cursor:           pointer;
  overflow:         hidden;
  margin:           0 auto; /* Center the video container */
}

.liteYoutube::before {
  content:          "";
  display:          block;
  padding-top:      56.25%; /* 16:9 aspect ratio */
}

.liteYoutube iframe {
  position:         absolute;
  top:              0;
  left:             0;
  width:            100%;
  height:           100%;
}

.placeholder {
  position:         absolute;
  top:              0;
  left:             0;
  width:            100%;
  height:           100%;
  background-size:  cover;
  background-position: center;
}

.playButton {
  position:         absolute;
  top:              50%;
  left:             50%;
  transform:        translate(-50%, -50%);
  font-size:        48px;
  color:            white;
  background-color: rgba(0, 0, 0, 0.6);
  padding:          10px 20px;
  border-radius:    50%;
  border:           none;
  pointer-events:   none;
}

