.customBtn {
    display:            flex;
    flex-direction:     row;

    margin:             1em auto;
    padding:            10px 20px;

    font-size:          16px;
    color:              #fff;
    text-shadow:        2px 2px 4px rgba(0, 0, 0, 0.8);
    background-color:   #e91e63;
    transition:         background-color 0.5s ease-in-out;

    border:             none;
    border-radius:      4px;

    cursor:             pointer;
    position:           relative;

    overflow:           hidden;
}

.customBtn:hover {
    background-color: var(--button-hover-color);
}

.customBtn:disabled {
    display:            flex;
    flex-direction:     row;

    margin:             1em auto;
    padding:            10px 20px;

    font-size:          16px;
    color:              #fff;
    text-shadow:        2px 2px 4px rgba(0, 0, 0, 0.8);

    border:             none;
    border-radius:      4px;

    position:           relative;
    overflow:           hidden;

    background-color:   #ccc;
    cursor:             not-allowed;
}


/* not sure where these are used? */
.submitButton:hover {
    background-color:   #b60940;
}

.submitButton:disabled {
    background-color:   #ccc;
    cursor:             not-allowed;
}
