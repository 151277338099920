
.breadcrumb {
    font-size:          14pt;
    margin-bottom:      20px;
    display:            flex;
    align-items:        center;
}

.link {
    text-decoration:    none;
    color:              #007bff;
    transition:         color 0.2s;
}

.link:hover {
    color:              #0056b3;
}

.separator {
    margin:             0 5px;
    color:              #6c757d;
}
