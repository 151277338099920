
.page {
    width:              100%;
    max-width:          120em;
    min-height:         100vh;
    overflow:           hidden;

    background-color:   var(--white-color);

    display:            flex;
    flex-direction:     column;
    justify-content:    center;
    align-items:        center;
    margin:             auto;
}

.content {
    display:            flex;
    flex-direction:     column;
    justify-content:    center;
    align-items:        center;

    padding:            1.5rem;
    max-width:          60em;
}

.content h1 {
    text-align:         center;
}

.content h2 {
    margin-bottom:      0em;
}

.content p {
    font-size:          1.3em;
    line-height:        1.6;
    color:              #555;
}

.content img {
    border-radius:      0.7em;
    box-shadow:         0 2px 10px rgba(0, 0, 0, 0.8);

    display:            block;
    margin:             0 auto;
    padding:            0;

    width:              100%;
    height:             auto;
    max-width:          45em;
}

.content a {
    color:              var(--blue-color);
    font-size:          1.4em;
}

.content a:hover {
    color:              var(--darkblue-color);
    transition:         color 0.5s ease-in-out;
    font-size:          1.4em;
}

.content figure {
    margin:     0;
    padding:    0;
    width:      100%;
}

.starSection {
    display:            flex;
    width:              100%;
    gap:                2em;
    margin-top:         4em;
}

.imageColumn {
    flex:               2;
    max-width:          20%;
}

.imageColumn img {
    width:              100%;
    height:             auto;
}

.textColumn {
    flex:               8;
    max-width:          80%;
}

.textColumn h2 {
    margin:             0;
    padding:            0;
}

.textColumn a {
    font-size:          1.3em;
    line-height:        1.6;
    text-decoration:    none;
    color: blue;
}

.textColumn a:hover {
    font-size:          1.3em;
    line-height:        1.6;
    text-decoration:    none;
    transition:         color 0.5s ease-in-out;
    color:              lightseagreen;
}


/* Tablet screens */
@media (max-width: 1024px) {
    .content {
        padding:        1rem;
        max-width:      50em;
    }

    .content p,
    .content a {
        font-size:      1.2em;
    }

    .starSection {
        gap:            1em;
    }

    .imageColumn {
        max-width:      30%;
    }

    .textColumn {
        max-width:      70%;
    }
}

/* Mobile screens */
@media (max-width: 767px) {
    .content {
        padding:        1rem;
        max-width:      100%;
    }

    .content h1 {
        font-size: 2.1em;
    }

    .content h2 {
        font-size: 1.4em;
    }

    .starSection {
        flex-direction: column;
        align-items:    center;
        text-align:     center;
        gap:            1.5em;
    }

    .imageColumn {
        max-width:      100%;
        order:          1; /* Ensures image appears above text on mobile */
    }

    .textColumn {
        max-width:      100%;
        order:          2;
    }

    .textColumn h2 {
        font-size:      1.8em;
    }

    .content p,
    .content a {
        font-size:      1em;
        line-height:    1.4;
    }
}
