.page {
    display:            flex;
    flex-direction:     column;
    align-items:        center;

    margin-top:         4em;
    overflow:           hidden;
}

.forecastContainer {

    display:            flex;
    flex-direction:     column;
    align-items:        center;

    padding:            1em;
    margin:             2em 1em;

    max-width:          80em;
    /*
    padding:            1em 4em 1em 4em;
    margin:             5em 12em 2em 12em;
    */

    border:             1px solid #ccc;
    border-radius:      8px;
    background-color:   #f9f9f9;
}

.forecastContainer h1 {
    text-align:         center;
    margin:             0.2em 0 0 0;
}

.forecastContainer h2 {
    text-align:         center;
    margin:             0 0 0.5em 0;
}

.forecastContainer p {
    font-family:        var(--primary-font);
    font-size:          1.4em;
}

.forecastContent {
    display:            flex;
    align-items:        center;
    gap:                20px;
}

.forecastSection {
    margin-bottom:      20px;
    padding:            20px;
    border:             1px solid #ccc;
    border-radius:      8px;
    background-color:   #f9f9f9;
}

.pageHeaderBar {
    display:            flex;
    flex-direction:     row;
    align-items:        center;
    width:              100%;
    margin-bottom:      0em;
    padding-bottom:     0em;
}

.column {
    display:            flex;
    flex-direction:     column;
    align-items:        center;
    margin-bottom:      1em;
}

@media (max-width: 767px) {

    .page {
        padding: 0 1em;
        margin: 0.5em;
    }

    .forecastContainer {
        padding: 1em;
        margin: 1em 1em;
        width: 95%;
    }

    .pageHeaderBar {
        flex-direction: column;
    }

    .column {
        margin: 1em;
    }

    .forecastContainer h1 {
        font-size: 2.5em;
    }

    .forecastContainer h2 {
        font-size: 1.8em;
    }

    .forecastContainer p {
        font-size: 1em;
    }
}
