
:root {
    --primary-color:            #333333;
    --secondary-color:          #2ecc71;
    --white-color:              #fff;
    --off-white-color:          #f9f9f9;
    --blue-color:               #007bff;
    --darkblue-color:           #0000aa;
    --darkgray-color:           #555;

    --background-color:         #ffffff;
    --container-color:          #f8d6c2;
    --light-sand-color:         #e4d6c2;
    --light-blue-color:         #add8ff;
    --light-gray-color:         #c3ccd1;
    --bold-blue-color:          #007BFF;
    --bold-orange-color:        #ff8c00;
    --salmon-color:             #e8e8e8;
    --sand-color:               #ffefd5;
    --light-salmon-color:       #feefef;

    --button-color:             #e91e63; /*#ff69b4*/
    --button-hover-color:       #b60940; /*#ff4d94;*/
    --button-txt-color:         #fff;
    --disabled-btn-color:       #ff69b4;
    --disabled-btn-hover-color: #ff4d94;
    --disabled-btn-txt-color:   #fff;

    --hero-font:                'Dancing Script', serif;
    --title-font:               'Dancing Script', serif;
    --primary-font:             'Kanit', serif;
    --link-font:                'Oswald', serif;
    --tiny-font:                'Abel', sans-serif;
}

body {
    color:                      var(--primary-color);
    font-family:                var(--primary-font);
    background-color:           var(--background-color);
    margin:                     0;
    padding:                    0;
    min-height:                 100vh;
    box-sizing:                 border-box;
}

h1, h2, h3, h4, h5, h6 {
  text-align:       left;
  font-family:      var(--title-font);
}

h1 {
  font-size:          4em;
}

h2 {
  font-size:          3.2em;
}

h3 {
  font-size:          2.1em;
}

p {
  text-align:         justify;
}

a {
  text-decoration:    none;
  color:              var(--white-color);
  transition:         color 0.5s ease-in-out;
}


a:visited {
}

a:hover {
  color: lightseagreen;
}


a:active {
  color: orange;
}



.centered-text {
  text-align:         center;
}

.page {
    width:            100%;
    min-height:       100vh;
    overflow:         hidden;

    background-color: var(--background-color);

    display:          flex;
    flex-direction:   column;

    justify-content:  center;
    align-items:      center;
}

