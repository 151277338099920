.bannerAd {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
}

.bannerImage {
  max-width: 100%;
  height: auto;
  border-radius: 5px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}
