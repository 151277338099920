.card {
  background:           rgba(196, 80, 0, 0.3);
  border-radius:        15px;
  padding:              1.5em;
  margin:               1.5em;
  backdrop-filter:      blur(5px);

  max-width:            28em;
  flex:                 1 1 calc(33.333% - 3em); /* Adjust to keep cards responsive */
  box-sizing:           border-box;


  border-width:         1px 0px 0px 1px;
  border-top:           1px solid white;
  border-left:          1px solid white;
  border-color:         #ffffff91;

  box-shadow:           20px 10px 40px 5px rgba(0,0,0,0.5);

  text-shadow:          2px 2px 4px rgba(0, 0, 0, 0.7);
}

.card:hover {
}

.cardTitle {
  text-align:           center;
  margin-bottom:        0.5em;
}

.cardSubtitle {
  text-align:           center;
  margin-bottom:        1.5em;
}

.cardAttributes {
  list-style:           none;
  padding:              0;
  margin-bottom:        1.5em;
  min-height:           16vh;
}

.cardAttributes li {
    font-size:          1.4em;
    margin-bottom:      0.5em;
}

.price {
    text-align:         center;
}

.cardButton {
    color:              #fff;
    background-color:   #e91e63;
    padding:            10px 20px;
    border-radius:      5px;
    cursor:             pointer;
    transition:         background-color 0.5s ease-in-out, transform 0.5s ease-in-out;

    font-family:        var(--primary-font);
    font-size:          1.6em;
}

.cardButton:hover {
    background-color:   #b60940;
    transform:          scale(1.1);
}

@media (max-width: 767px) {
  .card {
    flex: 1 1 100%;
  }
}
