.ordersList {
    margin: 20px;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color:   var(--salmon-color);
    box-shadow:         0 2px 4px rgba(0,0,0,0.2);
}

.ordersList h2 {
    margin-bottom: 20px;
    font-size: 24px;
    text-align: center;
}

.ordersList ul {
    list-style: none;
    padding: 0;
}

.orderItem {
    padding: 10px;
    border-bottom: 1px solid #ddd;
    cursor: pointer;
}

.orderItem:hover {
    background-color: #f0f0f0;
}

.pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}

.pagination button {
    margin: 0 10px;
    padding: 10px 20px;
    border: none;
    background-color: #007bff;
    color: #fff;
    border-radius: 4px;
    cursor: pointer;
}

.pagination button:disabled {
    background-color: #ccc;
}

.pagination span {
    margin: 0 10px;
}
