.accordion {
    width:      100%;
    border:     none !important;
    box-shadow: none !important;
}

.accordionItem {
    margin-bottom:  1em;
    overflow:       hidden;
    border:         none !important;
    box-shadow:     none !important;
}

.accordionHeader {

    cursor:             pointer;
    padding:            0 0 0 0;
    border:             none !important;
    box-shadow:         none !important;
}

.accordionHeader h3 {
    margin:         0;
}

.accordionBody {

    max-height:         0;
    overflow:           hidden;
    transition:         max-height 0.8s ease-in-out, padding 0.8s ease-in-out, opacity 0.6s ease-in-out;
    padding:            0 0 0 0 !important;
    opacity:            0;
    border:             none !important;
    box-shadow:         none !important;
}

.accordionBody.open {
    max-height: 180vh;
    padding:    0 0 1em 2em;
    opacity:    1;
}

.accordionBody div {
    border:     none !important;
    box-shadow: none !important;
}
