.page {
  position:         relative;
  width:            100%;
  height:           100vh;
  overflow:         hidden;
  display:          flex;
  align-items:      center;
  justify-content:  center;
  background-color: black;
}

.page h1 {
    font-size:          2.5em;
    margin-bottom:      0.5em;
}

.backgroundImage {
  position:         absolute;
  top:              0;
  left:             0;
  width:            100%;
  height:           100%;
  object-fit:       cover;
  z-index:          0;
  transition:       opacity 0.5s ease-in-out;
}

.content {
  position:         relative;
  z-index:          1;
  color:            white;
  text-align:       center;
  width:            100%;
  padding:          2em 16em 2em 16em;
  margin:           2em 2em 2em 2em;

  border-radius:    1em;
}

.cardsContainer {
  display:          flex;
  flex-wrap:        wrap;
  justify-content:  center;
  min-height:       60vh;
}

.ctaButton {
    padding:            0.8em 1.5em;
    font-size:          1em;
    color:              #fff;
    background-color:   #e91e63;
    border:             none;
    border-radius:      4px;
    cursor:             pointer;
    transition:         background-color 0.3s ease;
}

.ctaButton:hover {
  background-color:       #d81b60;
}






@media (min-width: 768px) {
  .cardsContainer {
    justify-content: space-between;
  }
}

@media (min-width: 1024px) {
  .cardsContainer {
    flex-wrap: nowrap;
  }
}
