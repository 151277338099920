.container {
    display:            flex;
    flex-direction:     column;
    align-items:        center;
    justify-content:    center;
    min-height:         100vh;
    text-align:         center;
}

.container p {
    margin-top:         20px;
    font-size:          1.2em;
    color:              var(--dark-gray-color);
}
