
.graphContainer {
    display:            flex;
    flex-direction:     row;
    justify-content:    space-between;
    align-items:        flex-start;
    width:              500px;
}

.chartContainer {
    flex:               1;
    min-width:          250px;
    max-width:          500px;
    height:             250px;
}


@media (max-width: 768px) {

    .graphContainer {
        flex-direction: column;
        width:          100%;
    }

    .chartContainer {
        width:          100%;
        max-width:      none;
        margin-left:    0;
    }
}
