.page {
    width:            100%;
    max-width:        120em;
    min-height:       100vh;
    overflow:         hidden;

    background-color: var(--light-gray-color);

    display:          flex;
    flex-direction:   column;
    justify-content:  center;
    align-items:      center;
    margin:           auto;
}


.hero-section {
  width: 100%;
  height: var(--banner-height); /* Using variable for height */
  position: relative;
  overflow: hidden; /* Ensures the image is cut off */
}

.hero-banner {
  width:            100%;
  height:           100%;
  object-fit:       cover; /* Ensures the image covers the entire area */
  object-position:  top var(--hero-banner-height-adjust); /* Adjusts the position upwards */
  position:         absolute;
  top:              0;
  left:             0;
  z-index:          1;
}

.hero-section::after {
  content:        '';
  position:       absolute;
  bottom:         0;
  left:           0;
  width:          100%;
  height:         50%; /* Adjust height as needed for the fade effect */
  background:     linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(64, 64, 64, 1) 100%);
  z-index:        2;
  pointer-events: none; /* Ensures the gradient does not interfere with interaction */
}

.hero-title {
  margin-top:   0.25em;
  position:     relative;
  text-align:   center;
  font-family:  var(--hero-font);
  font-size:    12vw;
  color:        white;
  text-shadow:  2px 2px 4px rgba(0, 0, 0, 0.8);
  z-index:      2;
}

.hero-text {
  margin-top:   -1em;
  position:     relative;
  text-align:   center;
  font-family:  var(--primary-font);
  font-size:    4vw;
  color:        white;
  text-shadow:  2px 2px 4px rgba(0, 0, 0, 0.8);
  z-index:      2;
}



.horoscopes-section {
  padding: 2em 2em 2em 2em;

}

.horoscopes-section h2 {
  font-size:  4em;
  color:      var(--white-color);
  text-shadow:  2px 2px 2px rgba(0, 0, 0, 0.8);
}

.call-to-action-section {
  padding: 2em 2em 6em 2em;
  margin: 0em 2em 0em 2em;
  width: 100%;
}

.call-to-action-section h2 {
  font-size:  4em;
  color:      var(--white-color);
  text-shadow:  2px 2px 2px rgba(0, 0, 0, 0.8);
}

.PreemContainer {
  display:          flex;
  flex-direction:   column;
  justify-content:  center;
  align-items:      center;
}


.mystic-image {
  width: 150px;
  height: auto;
  margin-bottom: 20px;
}

