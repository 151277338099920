.container {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    padding: 20px;
    box-sizing: border-box;
    background-color: #f9f9f9;
}

.column {
    max-width: 600px;
    width: 100%;
    text-align: center;
}

.title {
    font-size: 2.5rem;
    margin-bottom: 1rem;
    color: #333;
}

.subtitle {
    font-size: 1.5rem;
    margin-bottom: 1rem;
    color: #555;
}

.paragraph {
    font-size: 1rem;
    color: #666;
    text-align: justify;
}
