.page {
    min-height:         100vh;
}

.textContainer {
    display:            flex;
    flex-direction:     column;
    align-items:        center;

    padding:            1em;
    margin:             3em 1em 2em 1em;

    border-radius:      10px;
    background-color:   #fff;
    box-shadow:         0 2px 4px rgba(0,0,0,0.1);

    min-height:         80vh;
}

.textContainer h1 {
    text-align:         center;
    margin:             -1em 0 0.25em 0;
}

.textContainer h2 {
    margin:             1em 0 0 0;
}

.textContainer h3 {
    margin:             0;
}

.textContainer p {
    margin-top:         0;
}

.buttonRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items:        center;
}

.ctaButton {
    padding:            0.8em 1.5em;
    margin:             1em;
    font-size:          1.1em;
    color:              #fff;
    background-color:   #e91e63;
    border:             none;
    border-radius:      4px;
    cursor:             pointer;
    transition:         background-color 0.5s ease-in-out;

}

.ctaButton:hover {
    background-color:   #b60940;
}

.reading {
    display:            flex;
    flex-direction:     column;
    align-items:        center;

    max-width:          60em;
}

.title {
    font-size:          1.5em;
    color:              #333;
    margin-bottom:      1em;
    text-align:         center;
}


@media (max-width: 767px) {
    .textContainer {
        padding:        0.5em;
        margin:         0.5em;
    }

    .textContainer h2 {
        font-size:      2.2em;
        margin:         2em 0 0.1em 0;
        padding:        0.1em 0;
        text-align:     center;
    }

    .textContainer h3 {
        font-size:      1.6em;
        margin:         0.1em 0;
        padding:        0.1em 0;
    }

    .textContainer p {
        font-size:      1.2em;
    }
}
