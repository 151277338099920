
.page {
    font-family:        'Abel', sans-serif;
    margin:             5em 5em 5em 5em;
}

.container {
    margin:             4em 5em 4em 5em;
    padding:            4em 5em 4em 5em;
}

.container h1 {
    margin-top:         -0.4em;
}

.container h2 {
    margin:             0;
    padding:            0;
    font-family:        var(--primary-font);
    font-size:          1.4em;
    font-weight:        500;
}

.container h3 {
    margin:             0;
    padding:            0;
    font-family:        var(--primary-font);
    font-size:          1.2em;
    font-weight:        400;
}

.SmallPrint {
    font-size:          smaller;
}

.SmallPrint ul {
    list-style-type:    none;
}

.justifiedContent {
    text-align:         justify;
}


@media (max-width: 768px) {

    .page {
        display:        flex;
        flex-direction: column;
        align-items:    center;

    }

    .container {
        margin:         1em;
        padding:        1em;
        width:          88%;
    }

    .container h1 {
        margin-top:     0.4em;
    }
}
