/* Existing styles */

.footer {
    background-color:   black;
    color:              white;
    padding:            2em 0 0 0;
    width:              100%;
    overflow:           hidden;
}

.footer a {
    color:              #eef;
    text-decoration:    none;
    transition:         color 0.3s ease-in-out;
}

.footer a:hover {
    color:  #888;
}

.siteBanner h2 {
    text-align:         center;
    margin:             0.5em 0;
}

.siteBanner h3 {
    text-align:         center;
    margin:             0;
    font-size:          1em;
}

.footer .siteBanner a {
    color:              var(--text-color);
    text-decoration:    none;
}

.row {
    display:            flex;
    flex-direction:     row;
    justify-content:    center;
    flex-wrap:          wrap; /* Allow the row to wrap */
}

.column {
    flex:               1;
    padding:            1em;
    display:            flex;
    flex-direction:     column;
    align-items:        center;
    justify-content:    center;
    text-align:         center;
}

.footerLinks {
    padding:            0;
    list-style-type:    none;
    line-height:        2em;
}

.footerLinks li {
    text-decoration:    none;
}

.logo img {
    max-width:          100px; /* Adjusted for smaller screens */
}

.copyrightMessage {
    font-family:        var(--tiny-font);
    text-align:         center;
    padding:            0.5em 0;
}

/* Specific style for the starGoddessImage in the footer */
.starGoddessImage {
    max-width: 280px; /* Set a fixed max-width */
    height: auto; /* Maintain aspect ratio */
}

/* Media query for screens up to 767px */
@media (max-width: 767px) {
    .footer {
        padding: 1em 0;
    }

    .row {
        flex-direction: column;
    }

    .column {
        padding: 0.5em 0;
    }

    .siteBanner h2 {
        font-size: 3.2em;
    }

    .siteBanner h3 {
        font-size: 1.4em;
    }

    .footerLinks {
        line-height: 1.5em;
    }

    .logo img {
        max-width: 80px;
    }

    .starGoddessImage {
        max-width: 280px; /* Ensure it remains small on mobile */
        height: auto;
    }
}
