#top {
  position:             absolute;
  left:                 0;
  top:                  0;
}

.page {
    width:              100%;
    min-height:         100vh;
    overflow:           auto;
    box-sizing:         border-box;

    display:            flex;
    justify-content:    center;
    align-items:        center;
    width:              100%;
    padding:            6em 2em 6em 2em;
    background-color:   white;
    flex-direction:     column;

}

.container {
  max-width:            900px;
  display:              flex;
  flex-direction:       column;
  align-items:          center;
}

.page h1 {
  padding:              0;
  margin:               -0.5em 0 0 0;
  font-size:             2.6em;
}

.container h2 {
  padding: 0;
  margin: 0.25em 0;
}


.textContainer {
    flex:               1;
    display:            flex;
    flex-direction:     column;
    padding:            0;
    margin:             0;
}

.textContainer h2 {
    margin-bottom:      0em;
}

.textContainer h3 {
    margin-bottom:      0em;
}

.textContainer p {
    font-size:          1.3em;
    line-height:        1.6;
    margin: 0 0 1em 0;
}

.textContainer ul {
    list-style: none;
}

.threeCardSpread {
    width:              100%;
    display:            flex;
    justify-content:    center;
    align-items:        center;
}


.mediaContainer {
    flex: 1;
    display: flex;
    padding: 4em 1em;
    margin: 0em 1em;
    justify-content: center;
    align-items: center;
    position: relative;
}

.video {
    width: 640px;
    height: 360px;
    max-width: 100%;
    object-fit: contain;
}




@media (max-width: 767px) {

    .page {
        flex-direction:         column;
        height:                 auto;
        padding:                2em 1em;
        overflow:               auto;
        box-sizing:             border-box;
    }

    .mediaContainer {
        width:                  90%;

        padding:                2em 0em 4em 0em;
        margin:                 0;

        display:                flex;
        flex-direction:         column;
        justify-content:        center;
        align-items:            center;
    }

    .mediaContainer img {
        max-width:              90%;
        height:                 auto;
        border-radius:          2em;
        box-shadow:             4px 4px 8px rgba(0, 0, 0, 1.2), 6px 6px 20px rgba(0, 0, 0, 1);
    }

    .textContainer {
        width:                  90%;
        margin:                 0;
        padding:                1em;
    }

    .textContainer {
        text-align:             center;
        padding:                1em;
    }

    .textContainer h2 {
        font-size:              2.2em;
    }

    .textContainer h3 {
        font-size:              1.6em;
    }

    .textContainer p {
        font-size:              1.2em;
        text-align:             justify;
    }

    .buttonContainer {
        flex-direction:         column;
    }

    .priceContainer {
        margin-right:           0;
        margin-bottom:          1em;
    }

    .ctaButton {
        font-size:              1.2em;
    }
}


@media (max-width: 767px) {
    .buyNowContainer {
        width: 90%;
        margin: 0.5em auto;
        padding: 0.5em;
        box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
    }

    .priceContainer {
        text-align: center;
        padding:    0;
        margin:     0;
    }

    .priceTag {
        font-size: 1.6em;
    }

    .priceDescription {
        font-size: 0.8em;
    }

    .submitButton {
        padding:    8px 16px;
        font-size:  1em;
    }

    .agreementContainer {
        flex-direction: row;
        align-items:    center;
        margin:         0;
    }

    .checkboxLabel {
        text-align: center;
        font-size: 0.9em;
    }
}
