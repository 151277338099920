.page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    padding: 2em;
    text-align: center;
    background-color: #f9f9f9;
}

.page h1 {
    font-size: 2.5em;
    margin-bottom: 1em;
}

.page h2 {
    font-size: 1.5em;
    margin-bottom: 2em;
}

.formContainer {
    width: 100%;
    max-width: 600px;
}

.stepContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.page textarea {
    width: 100%;
    height: 150px;
    padding: 10px;
    font-size: 16px;
    border-radius: 4px;
    border: 1px solid #ccc;
    margin-bottom: 1.5em;
    resize: none;
}

.buttonContainer {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.nextButton, .backButton, .submitButton {
    padding: 10px 20px;
    font-size: 16px;
    background-color: var(--button-color);
    color: var(--button-txt-color);
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.nextButton:hover, .backButton:hover, .submitButton:hover {
    background-color: var(--button-hover-color);
}

.submitButton {
    background-color: #007BFF;
    color: white;
}

.submitButton:disabled {
    background-color: #ccc;
    color: #666;
    cursor: not-allowed;
}
