/* AdminCommonStyles.module.css */

.statBox {
    width:              190px;
    height:             180px;
    padding:            1.2em;
    margin:             1em auto;

    color:              var(--white-color);
    background-color:   var(--bold-blue-color);

    border-radius:      0.8em;
    box-shadow:         0 4px 8px rgba(0, 0, 0, 0.4);

    display:            flex;
    flex-direction:     column;
    align-items:        center;
    justify-content:    center;
}

.statBox h2, .statBox h3 {
    margin-top:         1.5em;
    width:              100%;
    text-align:         left;
    font-family:        var(--primary-font);
    font-size:          1.1em;
}

.mainNumber {
    display:            flex;
    text-align:         center;
    flex-direction:     column;
    justify-content:    center;
    align-items:        center;
    flex-grow:          2;
}

.bigNumber {
    font-size:          5.5em;
    font-weight:        bold;
    margin:             0;
    padding:            0;
    line-height:        0.8;
}

.mainLabel {
    font-size:          1.2em;
    margin:             0;
}

.minorNumbers {
    width:              100%;

    display:            flex;
    justify-content:    space-around;
    flex-grow:          3;
    align-items:        center;
}

.minorNumberContainer {
    text-align:         center;
}

.minorNumber {
    font-size:          1.5em;
    font-weight:        bold;
}

.minorLabel {
    font-size:          0.8em;
    margin-top:         5px;
}
