.form {
    display:            flex;
    flex-direction:     column;
    align-items:        center;
    padding:            20px;
    border:             1px solid #ccc;
    border-radius:      5px;
    background-color:   var(--light-salmon-color);
}

.form ul {
    width:              100%;

    display:            flex;
    align-items:        center;
    justify-content: center;

    list-style-type:    none;
    flex-wrap:          wrap;
    overflow-x:         auto;

    margin:             0.5em 0 0 0;
    padding:            0;

}

.form ul li {
    margin-right:       1em;
    white-space:        nowrap;
}

.form ul li:last-child {
    margin-right:       0;
}


.inputField {
    width:              100%;
    padding:            10px;
    margin:             10px 0;
    border:             1px solid #ccc;
    border-radius:      5px;
}

.blueButton {
    color:              var(--white-color);
    background-color:   var(--blue-color);

    width:              100%;

    display:            flex;
    justify-content:    center;
    align-items:        center;

    border:             none;
    border-radius:      0.25em;
    cursor:             pointer;

    font-size:          1.2em;
    font-weight:        600;

    padding:            0.6em 1em;
    margin-top:         1.4em;

    text-align:         center;
    text-shadow:        2px 2px 4px rgba(0, 0, 0, 0.5);

    transition:         background-color 0.3s ease;
}

.blueButton:hover {
    background-color:   var(--darkblue-color);
}

.message {
    color:              green;
    margin-top:         10px;
}


.error {
    color:              red;
    margin-top:         10px;
}
