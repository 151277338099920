.page {
    width:              100%;
    min-height:         100vh;

    display:            flex;
    flex-direction:     column;
    justify-content:    center;
    align-items:        center;

    padding:            0;
    overflow:           hidden;
}

.title {
    width:              100%;
    text-align:         center;
    margin:             0;
    padding:            0.5em 0 0 0;
}

.interface {
    display:            flex;
    width:              100%;
    justify-content:    center;
    align-items:        flex-start;
    flex-wrap:          wrap;
}

.container {
    display:            flex;
    flex-direction:     column;
    align-items:        center;
    justify-content:    center;

    padding:            0 1em;
    margin:             2em 0;

    min-width:          20em;
}

.container h2 {
    margin:             -3em 0 0 0;
}

.container h3 {
    margin:             0 0 1em 0;
    font-family:        var(--primary-font);
    font-weight:        500;
    font-size:          1.4em;
    color:              var(--darkgray-color);
}

.container button {
    display:            flex;
    flex-direction:     row;
}

.oauthButton {
    margin:             0.2em;
    padding:            0.6em .8em;
    font-size:          13pt;
    cursor:             pointer;
    border-radius:      5px;
    border:             none;
    color:              black;
    transition:         background-color 0.3s ease;
}

.container button:hover {
    background-color: #aaa;
}

.google {
    background-color: #eee; /*#DB4437;*/
}

.facebook {
    background-color: #4267B2;
}



@media (max-width: 768px) {

    .page {
        display:        flex;
        flex-direction: column;
        align-items:    center;
    }

    .title {
        margin-top:     2em;
    }

    .interface {
        display:        flex;
        flex-direction: column;
        align-items:    center;
    }

    .container {
        width:          100%;
        padding:        0em;
        margin:         1em 0;
    }
}
