.page {
    display:            flex;
    align-items:        center;
    justify-content:    center;
    min-height:         100vh;
    box-sizing:         border-box;

    margin: 0;
    padding: 2em 1em;
}

.container {
    display:            flex;
    flex-direction:     column;
    align-items:        center;
    justify-content:    center;
    text-align:         center;
    width:              100%;
    max-width:          20em;
}

.messageContainer {
    display:            flex;
    align-items:        center;
    justify-content:    center;
    min-height:         100vh;
    text-align:         center;
    color:              var(--blue-color);
}

.form {
    display:            flex;
    flex-direction:     column;
    align-items:        center;
    justify-content:    center;

    width:              100%;
    background-color:   var(--light-salmon-color);
    padding:            2em;
    border-radius:      0.8em;
    box-shadow:         0 4px 8px rgba(0, 0, 0, 0.4);
}

.input,
.button,
.buttonDisabled {
    height: 2em;
    font-size: 1em;
    width: 300px;
    box-sizing: border-box;
    margin: 1em 0;
}

.button,
.buttonDisabled {
    border: none;
    border-radius: 0.25em;
    color: var(--white-color);
    font-size: 1.2em;
    font-weight: 600;
    text-align: center;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;
}

.button {
    background-color: var(--blue-color);
}

.button:hover:enabled {
    background-color: var(--darkblue-color);
}

.buttonDisabled {
    background-color: gray;
    cursor: not-allowed;
}

@media (max-width: 768px) {

    .page {
        margin: 0;
        padding: 0 0 0 0;
    }

    .container, .messageContainer {
        width:      20em;
        max-width:  none;
        padding:    1.25em 0.9375em;
    }

    .container h2 {
        margin-top: -2em;
        font-size: 2.4em;
        text-align: center;
    }

    .form {
        background-color: var(--light-salmon-color);
        padding: 0.9375em;
        border-radius: 0.5em;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
    }

    .input,
    .button,
    .buttonDisabled {
        width: 100%; /* Make inputs and buttons span the full width */
        max-width: 18.75em; /* Limit the max width to maintain usability */
        margin: 0.625em auto; /* Center these elements and provide spacing */
        font-size: 1em; /* Increase font size for better readability */
    }

    .button,
    .buttonDisabled {

    }

    .button:hover:enabled {
        background-color: var(--darkblue-color); /* Ensure hover effect is noticeable */
    }

    p {
        font-size: 0.875em; /* Adjust paragraph font size for better readability on small devices */
        text-align: center; /* Center text for better presentation */
    }
}
