.page {
  display:          flex;
  flex-direction:   column;
  align-items:      center;
  justify-content:  flex-start;
  min-width:        100vw;
  min-height:       120vh;
  padding:          0;
  margin:           0;
  overflow:         hidden;
}

.header {
  text-align: center;
  margin: 2em 0 1em;
}

.header h1 {
  font-size: 2rem;
  color: #4caf50;
}

.header p {
  font-size: 1.2em;
  color: #666;
}

.main {
  width:      100%;
  min-height: 100vh;
}
