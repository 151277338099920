/* src/components/StarSignGrid.css */
.StarSignGrid {
  display:                  grid;
  grid-template-columns:    repeat(6, 1fr);
  gap:                      20px;
  margin-bottom:            2em;
}

/* Media query for screens up to 1024px */
@media (max-width: 1024px) {
    .StarSignGrid {
        grid-template-columns: repeat(3, 1fr); /* 3 columns for tablets */
    }
}

/* Media query for screens up to 768px */
@media (max-width: 767px) {
    .StarSignGrid {
        grid-template-columns: repeat(2, 1fr); /* 2 columns for small tablets */
    }
}

/* Media query for screens up to 480px */
@media (max-width: 480px) {
    .StarSignGrid {
        grid-template-columns: repeat(2, 1fr); /* 1 column for mobile phones */
    }
}
