.forecastSection {
    width:              100%;
    min-height:         90vh;
    overflow:           hidden;

    display:            flex;
    justify-content:    center;
    align-items:        center;
    flex-direction:     column;

    background-color:   var(--light-sand-color);
    color:              black;
}

.column {
    display:            flex;
    flex-direction:     column;
    width:              100%;
    overflow:           hidden;

    justify-content:    center;
    align-items:        center;

    margin:             0;
    padding:            4em;
}

.column h2 {
    text-align:         center;
}

/* Media query for screens up to 767px */
@media (max-width: 767px) {
    .forecastSection {
        padding: 4em 0;
    }

    .column {
        padding: 2em;
    }

    .column h2 {
        font-size: 2.2em;
    }
}
