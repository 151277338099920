.page {
    display:            flex;
    flex-direction:     column;
    align-items:        center;
    justify-content:    center;
    min-height:         100vh;
    text-align:         center;

    padding:            0 1em;
    background-color:   #f9f9f9;
}

.page h1 {
    font-size:          2em;
    margin-bottom:      0.5em;
}

.page h2 {
    font-size:          1.5em;
    margin-bottom:      1em;
}

.form {
    display:            flex;
    flex-direction:     column;
    align-items:        center;
    width:              100%;
    max-width:          400px;
}

.datePickerContainer {
    margin-bottom:      1.5em;
}

.submitButton {
    padding:            10px 20px;
    font-size:          16px;
    background-color:   var(--button-color);
    color:              var(--button-txt-color);
    border:             none;
    border-radius:      4px;
    cursor:             pointer;
    margin:             2em;
}

.submitButton:hover {
    background-color:   var(--button-hover-color);
}

.disabledButton {
  background-color: #ccc;
  color: #666;
  cursor: not-allowed;
}


/* Media query for screens up to 767px */
@media (max-width: 767px) {
    .page h1 {
        font-size: 1.8em;
    }

    .page h2 {
        font-size: 1.2em;
    }

    .submitButton {
        font-size: 14px;
        padding: 8px 16px;
    }
}
