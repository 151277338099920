
.page {
    width:              100%;
    max-width:          120em;
    min-height:         100vh;
    overflow:           hidden;

    background-color:   var(--white-color);

    display:            flex;
    flex-direction:     column;
    justify-content:    center;
    align-items:        center;
    margin:             auto;
}

.content {
    display:            flex;
    flex-direction:     column;
    justify-content:    center;
    align-items:        center;

    padding:            1.5rem;
    max-width:          60em;
    margin-top: 2em;
}

.content h1 {
    text-align:         center;
}

.content h2 {
    margin-bottom:      0em;
}

.content p {
    font-size:          1.3em;
    line-height:        1.6;
    color:              var(--darkgray-color);
}

.content img {
    border-radius:      0.7em;
    box-shadow:         0 2px 10px rgba(0, 0, 0, 0.8);

    display:            block;
    margin:             0 auto;
    padding:            0;

    width:              100%;
    height:             auto;
    max-width:          45em;
}

.content a {
    color:              var(--blue-color);
    font-size:          1em;
}

.content a:hover {
    color:              var(--darkblue-color);
    transition:         color 0.5s ease-in-out;
    font-size:          1em;
}

.content figure {
    margin:     0;
    padding:    0;
    width:      100%;
}

.content table {
    background-color: var(--sand-color);

    width:              100%;
    max-width:          50em;
    border-collapse:    collapse;

    margin:             2em auto;
    border:             1px solid #ddd;
    box-shadow:         0px 4px 8px rgba(0, 0, 0, 0.2);
}

.content table tr:nth-child(even) {
    background-color: var(--light-salmon-color);
}

.content table th,
.content table td {
    padding: 1em;
    border: 1px solid #ddd;
    text-align: left;
}

.content table th {
    background-color: var(--light-salmon-color);
    font-weight: bold;
}




.content ul {
    list-style:         none;
    padding:            0;
    margin:             0;

    color:              var(--darkgray-color);
    font-size:          1.3em;
    line-height:        1.6;

}

.content ul li {
    padding: 0.5em 0;
}




.twoColumns {
    display:            flex;
    gap:                2rem;
    justify-content:    space-between;
    align-items:        flex-start;
    width:              100%;
    max-width:          60em;
}

.column {
    flex:               2;
    max-width:          50%;
}

.column h2 {
    margin:             0;
    padding:            0;
}

.column a {
    font-size:          1.3em;
    line-height:        1.6;
    text-decoration:    none;
    color: blue;
}

.column a:hover {
    font-size:          1.3em;
    line-height:        1.6;
    text-decoration:    none;
    transition:         color 0.5s ease-in-out;
    color:              lightseagreen;
}


/* Tablet screens */
@media (max-width: 1024px) {
    .content {
        padding:        1rem;
        max-width:      50em;
    }

    .content p,
    .content a {
        font-size:      1.2em;
    }

    .twoColumns {
        gap:            1em;
    }

    .imageColumn {
        max-width:      30%;
    }

    .column {
        max-width:      70%;
    }
}

/* Mobile screens */
@media (max-width: 767px) {
    .content {
        padding:        1rem;
        max-width:      100%;
    }

    .content h1 {
        font-size: 2.1em;
    }

    .content h2 {
        font-size: 1.4em;
    }

    .twoColumns {
        flex-direction: row;
        align-items:    center;
        text-align:     center;
        gap:            1.5em;
    }

    .imageColumn {
        max-width:      100%;
        order:          1;
    }

    .column {
        max-width:      100%;
        order:          2;
    }

    .column h2 {
        font-size:      1.8em;
    }

    .content p,
    .content a {
        font-size:      1em;
        line-height:    1.4;
    }
}
