.subscribeBox {
    background-color:   var(--white-color);

    display:            flex;
    flex-direction:     column;
    align-items:        center;
    justify-content:    flex-start;

    box-sizing:         border-box;
    position:           relative;

    margin:             0em 1em 2em 1em;
    padding:            1.25em;

    border-radius:      0.5em;
    box-shadow:         0 0.25em 0.5em rgba(0, 0, 0, 0.6);

    max-width:          37.5em;
    min-width:          25em;
}

.subscribeBox h3 {
    margin:             0;
    padding:            0.1em;
    font-family:        var(--primary-font);
    font-size:          1.2em;
    font-weight:        400;
}

.subscribeBox p {
    padding: 0;
    margin: 1em 0 0 0;
}

.button {
    color:              var(--button-txt-color);
    background-color:   var(--button-color); /* was #e91e63*/
    border:             none;
    border-radius:      0.25em;
    cursor:             pointer;
    font-size:          1.4em;
    font-weight:        600;
    padding:            0.8em 1.25em;
    margin:             0;
    width:              12em;
    text-align:         center;
    transition:         background-color 0.3s;
    text-shadow:        2px 2px 4px rgba(0, 0, 0, 0.5);
}

.button:hover {
    background-color:   var(--button-hover-color); /*#b60940;*/
}

.button:disabled {
    background-color:   #ccc;
    cursor:             not-allowed;
}

.message {
    color: #D8000C;
    padding: 0.625em;
    border-radius: 0.25em;
    margin-top: 1em;
    height: 2em;
    line-height: 1.4em;
    transition: visibility 0.3s, opacity 0.3s ease-in-out;
    background-color: transparent;
}


/* Fade In animation */
.fadeIn {
    animation: fadeInAnimation 1.6s forwards;
}

/* Fade Out animation */
.fadeOut {
    animation: fadeOutAnimation 1.6s forwards;
}

/* Keyframes for fadeIn */
@keyframes fadeInAnimation {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

/* Keyframes for fadeOut */
@keyframes fadeOutAnimation {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}


/* Media Queries */
@media (max-width: 767px) {
    .subscribeBox {
        padding:            1em;
        margin:             0em 1em 2em 1em;

        max-width:          28em;
        min-width:          20em;
    }

    .subscribeBox h3 {
        font-size:          1em;
    }

    .button {
        padding:            0.6em 1em;
        font-size:          0.9em;
    }
}
