.banner {
    width:              100%;
    max-width:          45em;
    overflow:           hidden;

    display:            flex;
    flex-direction:     row;

    justify-content:    center;
    align-items:        center;

    background-color:   var(--white-color);

    box-shadow:         0 2px 24px rgba(0, 0, 0, 0.3);
    border-radius:      1em;

    margin:             4em 1em;
}

.banner a {
  color:           white;
  text-decoration: none;
}

.banner a:visited {
}

.banner a:hover {
    transition:     color 0.5s ease-in-out;
    color:          lightseagreen;
}

.column {
    flex:               1;
    margin:             1em;
    display:            flex;
    flex-direction:     column;
    align-items:        center;
}

.column h2 {
    text-align:         center;
    font-size:          2em;
    margin-bottom:      0.5em;
}

.column p {
    font-size:          1.3em;
    line-height:        1.6;
    color:              #555;
    max-width:          45em;
}

.callToAction {
    display:          flex;
    flex-direction:   column;
    justify-content:  center;
    align-items:      center;
    margin-top:       2em;
}

.button {
    color:              var(--button-txt-color);
    background-color:   var(--button-color); /* was #e91e63*/
    border:             none;
    border-radius:      0.25em;
    cursor:             pointer;
    font-size:          1.4em;
    font-weight:        600;
    padding:            0.4em 1em;
    margin:             1em 0 0 0;
    width:              12em;
    text-align:         center;
    transition:         background-color 0.3s;
    text-shadow:        2px 2px 4px rgba(0, 0, 0, 0.5);
    text-decoration:    none;
}

.button:hover {
    background-color:   var(--button-hover-color);

    transition:         background-color 0.5s ease-in-out;

    text-shadow:        2px 2px 4px rgba(0, 0, 0, 0.5);
    text-decoration:    none;
}

.button:disabled {
    background-color:   #ccc;
    cursor:             not-allowed;
}

.circularMask img {
    position:           absolute;
    top:                -610px;
    left:               -450px;
    transform:          scale(0.5);
}

.circularMask {
    overflow:           hidden;
    position:           relative;
    width:              300px;
    height:             300px;
    border-radius:      50%;
}

/* Responsive styling for mobile devices */
@media (max-width: 768px) {
    .banner {
        flex-direction: column;
        box-shadow:         0 2px 24px rgba(0, 0, 0, 0.3);
        border-radius:      1em;
        width: 90%;
        margin-bottom: 1em;
    }

    .column {
        width:          100%;
        margin:         1em 0;
        align-items:    center;
        text-align:     center;
    }

    .column h2 {
        font-size:      1.8em;
    }

    .column p {
        font-size:      1.1em;
        max-width:      100%;
        margin: 0;
        padding: 0;
    }

    .circularMask {
        width:          200px;
        height:         200px;
    }

    .circularMask img {
        position:           absolute;
        top:                -610px;
        left:               -480px;
        transform:          scale(0.5);
    }

    .button {
        width:          100%;
        font:           1em;
        margin:         0;
    }
}
