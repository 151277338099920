.heroSection {
    display:            flex;
    flex-direction:     column;
    align-items:        center;
    justify-content:    center;
    width:              100%;
    min-height:         100vh;
    position:           relative;
    overflow:           hidden;
}

.imageContainer {
    position:           absolute;
    top:                0;
    left:               0;
    width:              100%;
    height:             100%;
}

.starGoddessImage {
    z-index:            0;
    width:              100%;
    height:             100%;
    object-fit:         cover;
    object-position:    center;
}

.titleContainer {
    z-index:            10;
    max-width:          23em;
    text-align:         left;
    margin-left:        -52vw;
    margin-top:         15vh;
}

.titleContainer h1 {
    color:          var(--white-color);
    font-family:    var(--title-font);
    font-size:      4em;
    font-weight:    800;
    line-height:    0.9em;
    text-shadow:    2px 4px rgba(16, 16, 16, 1.0);
}

.titleContainer ul {
    color:          var(--white-color);
    font-family:    var(--primary-font);
    font-size:      2em;
    line-height:    1.1em;
    text-shadow:    2px 4px rgba(16, 16, 16, 1.0);
    list-style-type: none;
    padding-left:   0;
}

.titleContainer ul li {
    text-decoration: none;
}

.textContainer {
    z-index:        10;
    max-width:      16em;
    text-align:     left;
    margin-right:   -55vw;
    margin-top:     -4vh;
}

.textContainer h2 {
    line-height: 0.9em;
    font-family: var(--primary-font);
    font-size: 3.5em;
    color: var(--white-color);
    text-shadow: 2px 4px rgba(16, 16, 16, 1.0);
    padding: 0;
    margin: 0 0 0.25em 0;
}

.ctaButton {
    background-color:   var(--button-color);
    color:              var(--button-txt-color);
    font-family:        var(--primary-font);
    font-weight:        500;
    font-size:          1.4em;
    border:             none;
    border-radius:      0.2em;
    padding:            0.5em 0.7em;
    cursor:             pointer;
    text-shadow:        2px 2px 4px rgba(0, 0, 0, 0.5);
    transition:         background-color 0.3s ease-in-out;
    margin-bottom:      4em;
}

.ctaButton:hover, .ctaButton:focus {
    background-color:   var(--button-hover-color);
}

.ctaButton:disabled {
    background-color:   #ccc;
    color:              #666;
    cursor:             not-allowed;
}

.ctaButton:disabled:hover {
    background-color:   #ccc;
    color:              #666;
    cursor:             not-allowed;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.fadeInUpAnim {
  animation: fadeInUp 2s ease-out;
}


@media (max-width: 768px) {

    .heroSection {
        background-image: url('/public/img/astrology-hero-background-mobile.webp');
        background-size: cover;
        background-position: center;
    }

    .titleContainer {
        display:        flex;
        flex-direction: column;
        align-items:    center;
        margin-left:    auto;
        margin-right:   auto;
        margin-top:     24vh;
    }

    .titleContainer h1 {
        font-size:      3em;
        font-weight:    600;
        text-align:     center;
        margin:         0;
    }

    .titleContainer ul {

    }

    .titleContainer ul li {
        font-size:      0.9em;
    }

    .textContainer {
        margin-right:   5vw;
        margin-top:     1em;
        text-align:     center;
    }

    .textContainer h2 {
        text-align:     center;
        font-size:      2em;
    }
}
