
.spinner {
    width:              30px;
    height:             30px;
    border:             4px solid rgba(128, 128, 128, 0.1);
    border-radius:      50%;
    border-left-color:  #09f;
    margin:             0 auto;
    animation:          spin 1s linear infinite;
}

.spinner.small {
    width:            20px;
    height:           20px;
}

.spinner.tiny {
    width:            12px; /* New tiny size for fitting inside the button */
    height:           12px;
    border-width:     2px;
}

.spinner.medium {
    width:            80px;
    height:           80px;
}

.spinner.large {
    width:            360px;
    height:           360px;
}

.hidden {
  visibility:         hidden;
}

@keyframes spin {
  0%                 { transform: rotate(0deg); }
  100%               { transform: rotate(360deg); }
}
