.aboutSection {
    position:           relative;


    width:              100%;
    min-height:         84vh;
    overflow:           hidden;
    display:            flex;
    justify-content:    center;
    align-items:        center;
    padding:            4em 0;

    color:              black;
    background-color:   var(--off-white-color);
    background-size:    cover;
    flex-direction:     row;
}

.backgroundImage {
    position:           absolute;
    top:                0;
    left:               0;
    width:              100%;
    height:             100%;
    object-fit:         cover;
    z-index:            0;
    opacity:            0.7;
    transition:         opacity 0.5s ease-in-out;
}

.textContainer {
    flex:               1;
    text-align:         justify;
    z-index:            1;

    margin:             0em 5em 0em 5em;
    max-width:          45em;
}

.textContainer p {
    font-size:          1.3em;
    line-height:        1.6;
}

.imageContainer {
    flex:               1;
    margin:             0em 5em 0em 5em;
    max-width:          45em;
    z-index:            1;
    display:            flex;
    justify-content:    center;
    align-items:        center;
}

.aboutImage {
    max-width:          90%;
    height:             auto;
    border-radius:      2em;
    box-shadow:         4px 4px 8px rgba(0, 0, 0, 0.8), 6px 6px 20px rgba(0, 0, 0, 0.5);
}

/* Media query for screens up to 767px */
@media (max-width: 767px) {

    .aboutSection {
        flex-direction: column;
        padding:        2em 1em;
    }

    .textContainer {
        padding:        1em 2em;
        margin:         0;
        text-align:     left;
    }

    .textContainer h2 {
        font-size:      2.2em;
    }

    .textContainer p {
        font-size:      1.2em;
    }

    .imageContainer {
        padding:        2em 1em;
        margin:         0;
    }

    .aboutImage {
        max-width:      100%;
    }
}
