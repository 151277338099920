.page {
    width:              100%;
    display:            flex;
    flex-direction:     column;
    align-items:        center;
    padding:            20px;
    box-sizing:         border-box;
    min-height:         100vh;
}

.dashboardContainer {
    display:            flex;
    width:              100%;
    max-width:          1200px;
    margin-top:         6em;
    box-shadow:         0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius:      10px;
    overflow:           hidden;
}

.dashboardContainer h2 {
    margin:             0;
    text-align:         center;
}

.sidebar {
    flex:               1;
    display:            flex;
    flex-direction:     column;
    background-color:   var(--salmon-color);
    padding:            20px;
    box-shadow:         4px 0 8px rgba(0, 0, 0, 0.8);
}

.sidebar button {
    background:         none;
    border:             none;
    padding:            10px;
    font-size:          1rem;
    text-align:         left;
    cursor:             pointer;
    transition:         background-color 0.3s ease;
}

.sidebar button:hover {
    background-color:   #e0e0e0;
}

.menuOptions {
    margin:             1em 0;
    display:            flex;
    flex-direction:     column;
    align-items:        center;
}

.menuOptions button {
    width:              100%;
    text-align:         center;
    padding:            0.5em;
}

.mainContent {
    flex:               3;
    padding:            20px;
    background-color:   white;
    min-height:         80vh;
}

.notice {
    margin: 4em 0 0 0;
}

.subContainer {
    width:              100%;

    display:            flex;
    flex-direction:     column;
    align-items:        center;
    justify-content:    center;
}

/* Media query for screens up to 767px */
@media (max-width: 767px) {

    .dashboardContainer h2 {
        margin:             1em 0 0 0;
        padding:            0;
    }

    .dashboardContainer {
        flex-direction: column;
        margin-top: -0.8em;
    }

    .sidebar {
        flex-direction:     column;
        justify-content:    flex-start;
        box-shadow:         none;
        padding:            10px;
    }

    .sidebar button {
        padding:            10px;
        font-size:          0.9rem;
    }

    .mainContent {
        padding:            10px;
    }
}
