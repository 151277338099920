
.callToAction {
    display:            flex;
    justify-content:    center;
}

.ctaButton {
    font-size:          1.2rem !important;
    padding:            0.8rem 1.5rem;

    color:              var(--button-txt-color);
    background-color:   var(--button-color);

    border:             none;
    border-radius:      4px;
    cursor:             pointer;

    text-decoration:    none;
    text-shadow:        2px 2px 4px rgba(0, 0, 0, 0.5);

    transition:         background-color 0.5s ease-in-out;
}

.ctaButton:hover {
    background-color:   var(--button-hover-color);
}
